import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { Paper, Typography, IconButton } from '@mui/material';
import { useState, useEffect } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const InviteDialog = ({ id, open, onClose }) => {
    const [code, setCode] = useState("");
    useEffect(() => {
        fetch(`/api/noels/invite/${id}`).then((res) => {
            if (res.ok && res.status === 200) {
                res.json().then((data) => setCode(data.code))
            }
        })
    }, [id])
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>Créer un nouveau Noël</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ textAlign: 'center' }}>
                    Pour inviter des gens dans ton Secret Santa, partage le lien ci-dessous !
                </DialogContentText>
                <Paper
                    sx={{
                        backgroundColor: "primary.main",
                        padding: "0.3rem",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: "white"
                    }}
                >
                    <Typography>
                        {window.location.origin + "/join/" + code}
                    </Typography>
                    <IconButton
                        onClick={
                            () => {
                                navigator.clipboard.writeText(window.location.origin + "/join/" + code)
                            }
                        }
                    >
                        <ContentCopyIcon />
                    </IconButton>

                </Paper>
            </DialogContent>
        </Dialog>
    )
}

export default InviteDialog;