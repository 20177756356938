import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { TextField, Button } from '@mui/material';

const NewNoelDialog = ({ open, onClose }) => {

  const onSubmit = () => {
    const name = document.getElementById('name').value;
    const description = document.getElementById('description').value;
    const maxPrice = document.getElementById('max_price').value;
    const openingDate = document.getElementById('opening_date').value;
    const drawingDate = document.getElementById('drawing_date').value;
    console.log(name, description, maxPrice, openingDate, drawingDate);
    const data = {
      name,
      description,
      max_price:  parseFloat(maxPrice),
      opening_date: new Date(openingDate),
      draw_date: new Date(drawingDate),
      open: true,
      drawn: false,
    };
    console.log(data);
    fetch('/api/noels/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok && res.status === 200) {
        onClose();
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Créer un nouveau Noël</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          Pour créer un nouveau Noël, il vous suffit de remplir les champs ci-dessous.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nom du Noël"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          margin="dense"
          id="description"
          label="Description du Noël"
          type="text"
          fullWidth
          multiline
          variant="standard"
        />
        <TextField
          margin="dense"
          id="drawing_date"
          label="Date du tirage au sort"
          type="datetime-local"
          fullWidth
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="opening_date"
          label="Date de l'ouverture des cadeaux"
          type="datetime-local"
          fullWidth
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="max_price"
          label="Prix maximum"
          type="number"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={onSubmit}>Créer</Button>
      </DialogActions>

    </Dialog>
  )
}

export default NewNoelDialog;
