import { Box, Paper, Typography, Button } from '@mui/material';
import NoelPreview from '../components/NoelPreview';
import NewNoelDialog from '../components/NewNoelDialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const MainPage = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [noels, setNoels] = useState([]);
  const fetchNoels = () => {
    fetch('/api/noels/list').then(
      (res) => {
        if (res.ok && res.status === 200) {
          res.json().then((data) => setNoels(data));
        }
      }
    )
  }
  useEffect(() => {

    // const path = localStorage.getItem('path')
    // if (path !== null) {
    //   console.log()
    //   localStorage.removeItem('path')
    //   navigate(path)
    // }

    fetchNoels();
  }, []);

  // const noels = [
  //   {
  //     name: 'Noel 1',
  //     description: "Noel 1 description wtf c'est incroyablement long mais c'est bien comme ça ça passe à la lige",
  //     max_price: 10,
  //     id: 1,
  //     open: true,
  //     drawn: false,
  //     opening_date: '2021-12-25T17:30:00Z',
  //     drawing_date: '2021-12-10T00:00:00Z',
  //   },
  //   {
  //     name: 'Noel 1',
  //     description: "Noel 1 description wtf c'est incroyablement long mais c'est bien comme ça ça passe à la lige",
  //     max_price: 10,
  //     id: 2,
  //     open: false,
  //     drawn: true,
  //     opening_date: '2022-12-25T00:00:00Z',
  //     drawing_date: '2022-12-10T00:00:00Z',
  //   }
  // ]

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: '1rem',
      }}
    >
      <Paper
        elevation={8}
        sx={{
          width: "80%",
          margin: "auto",
          marginBottom: "1rem",
          padding: "1rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ margin: "auto", padding: "1rem" }} align="center"
        >
          🎅Bienvenue sur CSecret Santa !🎅
        </Typography>
        <Typography variant="p" align="center" sx={{ margin: "auto", padding: "1rem" }}>
          CSecret Santa est un site web qui permet d'organiser des tirages au sort entre amis, entre les membres de ton étage ou encore de ton association.
        </Typography>
        <Box
          sx={{
            width: '100%'
          }}
        >
        </Box>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Hi-Christmas",
            margin: 'auto',
            marginBottom: '1rem',
            fontSize: 25,
            fontWeight: 'bold',
            paddingTop: '0',
            paddingBottom: '0.2rem',
            paddingLeft: '1rem',
            paddingRight: '1rem'
          }}
          onClick={() => setOpenDialog(true)}
        >
          Organiser un père Noël Secret
        </Button>
        <NewNoelDialog open={openDialog} onClose={() => {
          setOpenDialog(false);
          fetchNoels();
        }} />
      </Paper>
      {noels.length !== 0 && <Paper
        elevation={8}
        sx={{
          width: "80%",
          margin: "auto",
          padding: "1rem",
          display: "flex",
          flexWrap: "wrap",
          height: "100%",
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {noels.map((noel) =>
          <NoelPreview key={noel.id} noel={noel} onClick={() => {
            navigate(`/noel/${noel.id}`)
          }} />
        )}
      </Paper>}
    </Box >
  );
}

export default MainPage;
