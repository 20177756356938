import { Box, Divider } from '@mui/material';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import * as React from 'react';

// santa preview json example:
// {
//   "name": "santa 1",
//   "description": "santa 1 description wtf c'est incroyablement long mais c'est bien comme ça ça passe à la lige",
//   "id": 1,
//   "filename": nom-du-fichier.png
// }


const SantaPreview = ({ santa, setPicture }) => {
    return (
        <Card
            sx={{
                '@media (max-width: 600px)': {
                    width: '100%',
                },
                '@media (min-width: 600px)': {
                    width: '100%',
                    maxHeight: '12rem',
                    display: 'flex',
                },
                minHeight: '10rem',
                margin: '1rem'
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    '@media (max-width: 600px)': {
                        width: '100%',
                    },
                    '@media (min-width: 600px)': {
                        width: "10rem",
                    },
                    padding: '0.2rem'
                }}
                image={process.env.PUBLIC_URL + '/' + santa.filename}
                alt="santa preview"
            />
            <Divider orientation="vertical" flexItem />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardContent sx={{
                    flex: '1 0 auto',
                    "&:last-child": {
                        paddingBottom: 0,
                    },
                }}>
                    <Typography component="div" variant="h5">
                        {santa.name}
                    </Typography>
                    <Typography variant="subtitle1" align="justify" color="text.secondary" component="div" lineHeight='1.5'>
                        {santa.description}
                    </Typography>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0.2rem 0',
                            justifyContent: 'right',
                            '> *': {
                                borderRadius: '1rem',
                                display: 'flex',
                            }
                        }}
                    >
                        <Button variant="contained" endIcon={<DoneIcon />} sx={{ padding: '0.5rem', height: '100%' }} color='error' onClick={() => { setPicture(santa.filename); window.localStorage.setItem('avatar', santa.filename) }}>
                            Select
                        </Button>

                    </Box>
                </CardContent>
            </Box >
        </Card >
    )
}

export default SantaPreview;
