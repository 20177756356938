import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useState } from 'react';

const TargetDialog = ({ open, onClose, id }) => {
  const [target, setTarget] = useState(null);
  useEffect(() => {
    fetch(`/api/noels/target/${id}`).then((res) => {
      if (res.ok && res.status === 200) {
        res.json().then((data) => setTarget(data.target))
      }
    })
  }, [id])
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Créer un nouveau Noël</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          {target !== null &&
            <Box>
              <Typography>
                Pour ce Noël secret, tu as été tiré au sort pour offrir un cadeau à...
              </Typography>
              <Typography>
                  {target.first_name} {target.last_name} !
              </Typography>
            </Box>
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default TargetDialog;
