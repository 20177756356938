import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link"
import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SantaPreview from "../SantaPreview";
import SantasList from "./Santas";
import { useState } from "react";
import { Typography } from "@mui/material";
import { useEffect } from "react";

function DialogSetAvatar(buttonText, santas, setState) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link textAlign="center" sx={{ fontFamily: "Hi-Christmas", fontSize: 50, fontWeight: "bold" }} onClick={handleClickOpen}>
        {buttonText}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choisis ton Père Noël</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sélectionne un Père Noël dans la liste et regarde ton avatar en haut à droite changer !
          </DialogContentText>
          {santas.map((santa) => <SantaPreview key={santa.name} santa={santa} setPicture={setState} />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

function ResponsiveAppBar({ user }) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = [user.first_name + " " + user.last_name, "Déconnexion"];

  const [avatar, setState] = useState('santa.jpg')

  useEffect(() => {
    var savedAvatar = window.localStorage.getItem('avatar');
    if (savedAvatar) { setState(savedAvatar) }
  }, [])
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);

  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Typography variant="h3" component="div" sx={{
          flexGrow: 1,
          fontFamily: 'Hi-Christmas',
          textAlign: 'center',
          marginBottom: '0.5rem',
          fontSize: 'xxxxx-large',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
          width: '100%'
        }}
          onClick={() => navigate('/')}
        >
          ( Joyeux Noël )
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, height: '2em', width: '2em', position: 'absolute', right: '1%', transform: 'translate(0,-50%)' }}>
              <Avatar alt={user.first_name} src={process.env.PUBLIC_URL + '/' + avatar} sx={{ height: '100%', width: '100%' }} />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >

            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {setting !== "Déconnexion" && DialogSetAvatar(setting, SantasList, setState)}
                {setting === "Déconnexion" && <Link textAlign="center" sx={{ fontFamily: "Hi-Christmas", fontSize: 50, fontWeight: "bold" }} onClick={() => { navigate("/" + setting) }}>{setting}</Link>}
              </MenuItem >
            ))
            }
          </Menu>
        </Box >
      </Toolbar >
    </AppBar >
  );
}
export default ResponsiveAppBar;
