const SantasList = [
    {
        name: 'Petit Papa Noël',
        description: "Le classique. Un bon vieux papa noël chaleureux et sympathique. On a envie de s'asseoir sur ses genoux et de lui dire qu'on veut une petite lambo pour Noël.",
        filename: 'santa.jpg',
        id: 1,
    },
    {
        name: 'Cooler Santa',
        description: "Livre les cadeaux en skate.",
        filename: 'cool-santa.png',
        id: 1,
    },
    {
        name: 'Cursed Santa',
        description: "Il a l'air un peu moins cool",
        filename: 'cursed-santa.png',
        id: 2,
    }
]

export default SantasList
