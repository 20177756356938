import { Box, Divider, Paper, Tooltip } from '@mui/material';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EuroIcon from '@mui/icons-material/Euro';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

// noel preview json example:
// {
//   "name": "Noel 1",
//   "description": "Noel 1 description wtf c'est incroyablement long mais c'est bien comme ça ça passe à la lige",
//   "max_price": 10,
//   "id": 1,
//   "open": true,
//   "drawn": false,
//   "opening_date": "2021-12-10T00:00:00Z",
//   "drawing_date": "2021-12-25T00:00:00Z"
// }
const NoelPreview = ({ noel, onClick }) => {
  const openingDate = new Date(noel.opening_date);
  const drawingDate = new Date(noel.draw_date);
  const presentDate = new Date();
  var state = "Ouvert";
  if (presentDate < openingDate && noel.drawn) {
    state = "En cours";
  }
  if (presentDate > openingDate && noel.drawn) {
    state = "Terminé";
  }



  return (
    <Card
      sx={{
        '@media (max-width: 600px)': {
          flexDirection: 'column'
        },
        '@media (min-width: 600px)': {
          flexDirection: 'row'
        },
        display: 'flex',
        minHeight: '10rem',
        margin: '0.1rem',
        flexGrow: 1,
        backgroundColor: '#3FD485',
      }}
      onClick={onClick}
    >
      <CardMedia
        component="img"
        sx={{
          '@media (max-width: 600px)': {
            width: '100%',
          },
          '@media (min-width: 600px)': {
            width: "10rem",
          },
          padding: '0.2rem'
        }}
        image={`${process.env.PUBLIC_URL}/ball.png`}
        alt="Noel preview"
      />
      <Divider orientation="vertical" flexItem />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          "&:last-child": {
            paddingBottom: 0,
          },
        }}>
          <Box>
            <Typography component="div" variant="h5">
              {noel.name}
            </Typography>
            <Typography variant="subtitle1" align="justify" color="text.secondary" component="div">
              {noel.description}
            </Typography>
          </Box>

          <Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0.3rem auto',
                flexWrap: "wrap",
                '> *': {
                  display: 'flex',
                  flexGrow: 1,
                  margin: '0.1rem',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }
              }}
            >
              <Tooltip title={state}>
                <Paper sx={{ padding: '0.3rem', borderRadius: '1rem' }}>
                  {state === "Ouvert" && <LockOpenIcon></LockOpenIcon>}
                  {state === "En cours" && <RotateLeftIcon></RotateLeftIcon>}
                  {state === "Terminé" && <PublishedWithChangesIcon></PublishedWithChangesIcon>}
                </Paper>
              </Tooltip>
              <Tooltip title="Prix maximum">
                <Paper sx={{ padding: '0.3rem', borderRadius: '1rem' }}>
                  <EuroIcon></EuroIcon>
                  <Typography variant="subtitle1" align="justify" color="text.secondary" component="div">
                    {noel.max_price}
                  </Typography>
                </Paper>
              </Tooltip>
              <Tooltip title={noel.open ? "Date du tirage" : "Date d'ouverture des cadeaux"}>
                <Paper sx={{ padding: '0.3rem', borderRadius: '1rem' }}>
                  {noel.open ? <HowToVoteIcon></HowToVoteIcon> : <CelebrationIcon></CelebrationIcon>}
                  <Typography variant="subtitle1" align="justify" color="text.secondary" component="div">
                    {noel.open ? drawingDate.toLocaleDateString() : openingDate.toLocaleDateString()}
                  </Typography>
                </Paper>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  )
}

export default NoelPreview;
