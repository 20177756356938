import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

const JoinPage = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    useEffect(() => {
        fetch(`/api/noels/join/${code}`).then((res) => {
            if (res.ok && res.status === 200) {
                res.json().then((data) => navigate(`/noel/${data.id}`))
            }
        });
    }, [code, navigate])
    return (
        <Box></Box>
    )
}

export default JoinPage