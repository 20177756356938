import { ThemeProvider, Toolbar, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
import ResponsiveAppBar from './components/global/Navbar';
import MainPage from './pages/MainPage';
import NoelPage from './pages/NoelPage';
import LoginPage from './pages/LoginPage';
import JoinPage from './pages/JoinPage';

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    fetch('/api/auth/whoami').then((res) => {
      if (res.ok && res.status === 200) {
        res.json().then((user) => setUser(user));
      }
    });
  }, []);
  return (
    <ThemeProvider theme={
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#D6001C',
          },
          secondary: {
            main: '#007502',
          },
          alternate: {
            main: '#1F2766',
          },
          contrasted: {
            main: '#FFFFFF',
          }
        },
      })
    }>
      <BrowserRouter>
        {
          user !== null &&
          <Box>
            <ResponsiveAppBar user={user} />
            <Toolbar />
          </Box>
        }
        <Routes>
          {user !== null && <Route path="/" element={<MainPage />} />}
          {user !== null && <Route path="/noel/:id" element={<NoelPage user={user} />} />}
          {user !== null && <Route path="/join/:code" element={<JoinPage />} />}
          {user === null && <Route path="*" element={<LoginPage />} />}
        </Routes>
      </BrowserRouter>
    </ThemeProvider >
  );
}

export default App;
