import { Celebration } from "@mui/icons-material";
import { Box, Paper, Divider, Typography, Button, Tooltip } from "@mui/material";
import { Fab } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useParams } from "react-router-dom";
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import EuroIcon from '@mui/icons-material/Euro';
import { useEffect, useState } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import InviteDialog from "../components/InviteDialog";
import TargetDialog from "../components/targetDialog";

const isAdmin = (user, noel) => {
  const adminLogins = noel.admins.map((user) => user.Login)
  return adminLogins.includes(user.login)
}

const NoelPage = ({ user }) => {
  let { id } = useParams();
  const [noel, setNoel] = useState(null);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [targetDialogOpen, setTargetDialogOpen] = useState(false);
  useEffect(() => {
    fetch(`/api/noels/show/${id}`).then((res) => {
      if (res.ok && res.status === 200) {
        res.json().then((noel) => {
          setNoel(noel);
        });
      }
    });
  }, [id]);
  const closeOrOpenNoel = () => {
    if (noel.drawn) {
      return
    }
    var url;
    if (noel.open) {
      url = `/api/noels/close/${id}`;
    } else {
      url = `/api/noels/open/${id}`;
    }
    fetch(url, {
      method: "POST",
    }).then((res) => {
      if (res.ok && res.status === 200) {
        res.json().then(() => {
          setNoel({ ...noel, open: !noel.open });
        });
      }
    });
  };
  const drawOrResetNoel = () => {
    var url;
    if (noel.drawn) {
      url = `/api/noels/reset/${id}`;
    } else {
      url = `/api/noels/draw/${id}`;
    }
    fetch(url, {
      method: "POST",
    }).then((res) => {
      if (res.ok && res.status === 200) {
        res.json().then(() => {
          setNoel({ ...noel, drawn: !noel.drawn });
        });
      }
    });
  };

  // const noel = {
  //   name: "Noël Secret d'une asso",
  //   description: "S'offrir des cadeaux c'est incroyable 🎁, donc on va faire ça tous ensemble autour d'un exquis repas 🍽️",
  //   max_price: 10,
  //   id: 1,
  //   open: false,
  //   drawn: true,
  //   opening_date: "2021-12-25T17:30:00Z",
  //   drawing_date: "2021-12-10T00:00:00Z",
  //   users: [
  //     {
  //       id: 1,
  //       login: "user1",
  //       first_name: "Jean",
  //       last_name: "Dupont",
  //     },
  //     {
  //       id: 2,
  //       login: "user1",
  //       first_name: "Jean",
  //       last_name: "Dupont",
  //     },
  //     {
  //       id: 3,
  //       login: "user1",
  //       first_name: "Jean",
  //       last_name: "Dupont",
  //     }
  //   ],
  //   admins: [
  //     {
  //       id: 1,
  //       login: "user1",
  //       first_name: "Jean",
  //       last_name: "Dupont",
  //     }
  //   ]
  // }
  return (

    <Box
      sx={{
        margin: '1rem',
      }}
    >
      {noel !== null && (
        <Box
          elevation={8}
          sx={{
            width: "90%",
            margin: "auto",
            padding: "0.3rem",
            height: "100%",
            opacity: "0.98"
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: "100%",
              marginTop: "1rem",
              opacity: "1"
            }}
          >
            <Box
              sx={{
                '@media (min-width: 600px)': {
                  display: "flex",
                },
              }}
            >
              <Box
                sx={{
                  margin: "1rem",
                  overflow: "hidden"
                }}
              >
                <Typography align="center" variant="h4"
                >
                  {noel.name}
                </Typography>
                <Typography variant="subtitle1" align="center" color="text.secondary" component="div">
                  Créé par {noel.admins[0].first_name} {noel.admins[0].last_name}
                </Typography>
                {noel.drawn &&
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        margin: "0.5rem auto",
                        width: "100%",
                        borderRadius: "1rem",
                        backgroundColor: "primary.secondary",
                        color: "white",
                      }}
                      onClick={() => setTargetDialogOpen(true)}
                    >
                      Voir ma cible 🎯
                    </Button>
                    <TargetDialog open={targetDialogOpen} onClose={() => setTargetDialogOpen(false)} id={id} />
                  </Box>
                }
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="center" color="primary.secondary" component="div"
                  fontSize="1.2rem"
                >
                  {noel.description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "0rem",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    "> *": {
                      margin: "0.5rem 2rem",
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    },
                  }}
                >
                  <Tooltip title="Date d'ouverture des cadeaux">
                    <Paper
                      sx={{
                        backgroundColor: "primary.main",
                        borderRadius: "2rem",
                      }}
                    >
                      <Celebration color="contrasted" />
                      <Typography variant="h6" align="left" color="white" component="div">
                        {(new Date(noel.opening_date)).toLocaleDateString()}
                      </Typography>
                    </Paper>
                  </Tooltip>
                  <Tooltip title="Date du tirage au sort">
                    <Paper
                      sx={{
                        backgroundColor: "primary.main",
                        borderRadius: "2rem",
                      }}
                    >
                      <HowToVoteIcon color="contrasted" />
                      <Typography variant="h6" align="left" color="white" component="div">
                        {(new Date(noel.draw_date)).toLocaleDateString()}
                      </Typography>
                    </Paper>
                  </Tooltip>
                  <Tooltip title="Prix maximum">
                    <Paper
                      sx={{
                        backgroundColor: "primary.main",
                        borderRadius: "2rem",
                      }}
                    >
                      <EuroIcon color="contrasted" />
                      <Typography variant="h6" align="left" color="white" component="div">
                        {noel.max_price}
                      </Typography>
                    </Paper>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Divider />
          <Paper
            elevation={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              marginBottom: "1rem",
              padding: "1rem",
              opacity: "1",
              backgroundImage: `url('${process.env.PUBLIC_URL}/noelbackground.png')`,
              backgroundSize: "contain",
              backgroundRepeat: "repeat"
            }}
          >
            {
              isAdmin(user, noel) &&
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  color: "white",
                  justifyContent: "space-evenly",
                  "> *": {
                    margin: "auto",
                    display: "flex",
                    padding: "0rem",
                  },

                }}
              >
                <Fab color="primary"
                  onClick={() => setInviteDialogOpen(true)}
                >
                  <PersonAddIcon />
                </Fab>
                <InviteDialog id={id} open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
                {
                  !noel.drawn &&
                  <Fab color="secondary"
                    onClick={closeOrOpenNoel}
                  >
                    {noel.open ? <LockOpenIcon /> : <LockIcon />}
                  </Fab>
                }
                {
                  !noel.open &&
                  <Fab color="primary"
                    onClick={drawOrResetNoel}
                  >
                    {noel.drawn ? <ReplayIcon /> : <HowToVoteIcon />}
                  </Fab>
                }
              </Box>
            }

            <Box
              sx={{
                display: "flex",
                margin: "auto",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {noel.users.map((user) => (
                <Button
                  key={user.id}
                  sx={{
                    margin: "1rem",
                    padding: "1rem",
                    width: "fit-content",
                    backgroundColor: "white",
                    borderRadius: "3em",
                  }}
                  onClick={() => {
                    window.open(`https://linkcs.fr/user/${user.Login}`, '_blank').focus();
                  }}
                >
                  <Typography variant="h5" align="left" color="text.primary" component="div"
                    fontSize="1.2rem"
                  >
                    {user.first_name} {user.last_name}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default NoelPage;
