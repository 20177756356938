import { Box, Button, Paper } from "@mui/material";

const redirectLogin = (path) => {
  window.location.href = `/api/auth/login?post_login_redirect=${encodeURIComponent(path)}`;
};

const LoginPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 1,
            boxShadow: 1,
            p: 1,
          }}
        >
          <img alt="Sexy Santa" src={`${process.env.PUBLIC_URL}/noel-joyeux-noel.gif`} />
          <Button variant="contained" sx={{ mb: 1, marginTop: "1rem" }} onClick={() => redirectLogin(window.location.pathname)}>
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default LoginPage;
